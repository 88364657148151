.tg-bot-wr
  display: flex
  justify-content: space-between
  gap: 100px
  @media(max-width: 991.5px)
    flex-direction: column
    gap: 40px
.tg-bot
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 20px
.tg-bot-text
  font-size: 22px
  @media (max-width: 991.5px)
    font-size: 15px