.partners-wr
  display: grid
  grid-auto-flow: column
  grid-gap: 60px
  justify-content: center
  align-items: center
  width: 100%
  user-select: none
  @media (max-width: 711px)
    grid-auto-flow: row
  @media (max-width: 415px)
    grid-gap: 20px

.partner
  position: relative
  overflow: hidden

.partner__desc
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  padding: 0 20px
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  color: #222
  font-weight: 800
  font-size: 18px
  transform: translateY(100%)
  background-color: rgba(255, 255, 255, 0.98)
  transition: .3s


.partner:hover .partner__desc
  transform: translateY(0)

.partner__img
  height: 80px
  & img
    height: 100%
  @media (max-width: 415px)
    height: 50px
