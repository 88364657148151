.gym-wr
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-template-rows: repeat(3, 200px)
  grid-template-areas: 'gym-1 gym-2 gym-3 gym-3' 'gym-1 gym-5 gym-5 gym-6' 'gym-7 gym-8 gym-9 gym-9'
  width: 100%
  min-height: 200px
  grid-gap: 10px
  user-select: none
  @media (max-width: 991.5px)
    grid-template-columns: repeat(3, 1fr)
    grid-template-rows: repeat(4, 200px)
    grid-template-areas: 'gym-1 gym-3 gym-3' 'gym-1 gym-5 gym-5' 'gym-2 gym-6 gym-7' 'gym-8 gym-9 gym-9'
  @media (max-width: 677.5px)
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(5, 200px)
    grid-template-areas: 'gym-1 gym-2' 'gym-1 gym-6' 'gym-5 gym-5' 'gym-9 gym-9' 'gym-3 gym-3' 'gym-7 gym-8'

.gym
  border-radius: 20px
  background-color: #f1f6f9
  color: #1E1E1E
  padding: 10px 20px
  overflow: hidden

.gym-black
  background-color: #2B2831
  color: #fff

.gym__title
  font-size: 22px
  padding-bottom: 10px
  font-weight: 800
  cursor: default
  user-select: none
  text-align: center
  width: 0
  transition: .5s ease
  @media (max-width: 449.5px)
    font-size: 17px

.gym__title-soon
  position: relative
  &:after
    position: absolute
    content: 'Скоро'
    padding: 5px 15px
    background-color: #ff5400
    color: #fff
    border-radius: 10px
    font-size: 12px
    font-weight: 400

.gym__title-mb-20
  margin-bottom: -20px
  @media (max-width: 374.5px)
    margin-bottom: -10px

.gym__title-small
  @media (max-width: 374.5px)
    font-size: 14px

.gym__img
  position: relative
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: calc(100% - 30px)
  & img
    height: 100%
    max-width: 100%
    object-fit: contain
    transition: .5s ease

.gym__img-text
  position: absolute
  transform: translateY(260px)
  transition: .5s ease
  cursor: default
  @media (max-width: 499.5px)
    font-size: 14px

.gym__img-text-small
  @media (max-width: 499.5px)
    font-size: 12px
  @media (max-width: 374.5px)
    font-size: 10px

.gym:hover .gym__title
  width: 100%

.gym:hover .gym__title-soon:after
  margin-left: 10px

.gym:hover .gym__img .gym__img-text
  transform: translateY(0%)

.gym:hover .gym__img img
  opacity: 0



