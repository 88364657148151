.features
  display: flex
  flex-wrap: wrap
  justify-content: center
  grid-gap: 20px
.feature
  width: 320px
  display: grid
  grid-gap: 20px
  grid-template-rows: 86px 57px 40px
  align-items: center
  background-color: #fff
  border-radius: 20px
  padding: 20px

.feature__img
  display: flex
  justify-content: center
  align-items: center

.feature__img svg
  height: 100%

.feature__title
  font-size: 24px
  font-weight: 800
  text-align: center
  color: #1E1E1E
  cursor: default
  user-select: none

.feature__subtitle
  font-size: 14px
  color: #8A8A8A
  text-align: center
  cursor: default
  user-select: none

.icon-features
  height: 80px
