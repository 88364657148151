.coaches
	display: flex
	flex-wrap: wrap
	justify-content: center
	margin-bottom: 30px

.coach
	position: relative
	width: 300px
	height: 450px
	overflow: hidden
	background-color: #fff
	border-radius: 20px
	transition: .3s
	margin: 0 20px 20px
	padding-bottom: 50px
	&.active
		height: 950px

.coach-6.active
	height: 960px
.coach-2.active
	height: 880px
.coach-7.active
	height: 1060px
.coach-8.active
	height: 1020px
.coach-12.active
	height: 920px
.coach-13.active
	height: 980px
.coach-14.active
	height: 910px
.coach-15.active
	height: 990px
.coach-16.active
	height: 880px

.coach-arrow
	position: absolute
	bottom: 3px
	display: flex
	justify-content: center
	align-items: center
	width: 300px
	height: 30px
	cursor: pointer

.coach-arrow-1
	width: 30px
	height: 3px
	background-color: #FF5400
	border-radius: 10px
	transform-origin: center
	transform: rotate(30deg) translateX(3px)
	transition: 0.8s

.coach-arrow-2
	width: 30px
	height: 3px
	background-color: #FF5400
	border-radius: 10px
	transform-origin: center
	transform: rotate(-30deg) translateX(-3px)
	transition: 0.8s

.coach.active .coach-arrow-1
	transform: rotate(-30deg) translateX(3px)

.coach.active .coach-arrow-2
	transform: rotate(30deg) translateX(-3px)

.coach__img
	position: relative
	border-radius: 20px
	overflow: hidden
	width: 300px
	height: 350px
	transition: .3s
	box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.2)
	& img
		object-fit: cover
		width: 100%
		height: 100%
.coach__social
	position: absolute
	bottom: 5px
	left: 5px

.coach.active .coach__img
		box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4)

.coach__name
	font-size: 26px
	line-height: 28px
	font-weight: 700
	color: #222
	margin-top: 20px
	padding-left: 20px
	padding-right: 20px
	transition: .5s

.coach.active .coach__name
	font-size: 24px

.coach__prof
	font-size: 16px
	line-height: 20px
	font-weight: 400
	color: #222
	padding-left: 20px
	padding-right: 20px
	margin-bottom: 30px
	transition: .5s

.coach__prof-small
	font-size: 14px

.coach.active .coach__prof
	margin-bottom: 10px

.coash__info
	padding-left: 20px
	padding-right: 20px

.coash__tel
	display: block
	color: #333
	font-size: 20px
	font-weight: 700
	margin-bottom: 10px

.directions
	margin-bottom: 10px

.directions__title
	font-size: 14px
	font-weight: 700
	margin-bottom: 8px

.directions__list
	list-style: none

.directions__item
	position: relative
	font-size: 12px
	font-weight: 400
	margin-bottom: 8px
	padding-left: 24px

.direction__check
	position: absolute
	top: 0
	left: 0

.direction__check-o
	fill: #FF5400

.direction__check-v
	stroke: #fff

.education
	margin-bottom: 10px

.education__title
	font-size: 14px
	font-weight: 700
	margin-bottom: 8px

.education__text
	font-size: 12px
	font-weight: 400
	margin-bottom: 8px

.coach-prices
	margin-bottom: 10px

.coach-prices__title
	font-size: 14px
	font-weight: 700
	margin-bottom: 8px

.coach-prices__text
	font-size: 12px
	font-weight: 400
	margin-bottom: 3px

