@use "sass/main"
@use "sass/header"
@use "sass/mobile"
@use "sass/btns"
@use "sass/s-main"
@use "sass/features"
@use "sass/tg-bot"
@use "sass/price"
@use "sass/gym"
@use "sass/partners"
@use "sass/coaches"
@use "sass/training"
@use "sass/address"
@use "sass/footer"
@use "sass/container"
@use "sass/alert"
