.address
  display: flex
  justify-content: space-between
  @media (max-width: 839.5px)
    flex-direction: column-reverse
    justify-content: center
    align-items: center

.contacts
  display: flex
  flex-direction: column
  justify-content: center
  height: 400px
  @media (max-width: 839.5px)
    padding: 30px 0
    height: auto
  &__item
    height: 30px
    margin-bottom: 10px
    & .button__body
      font-weight: 800
      font-size: 20px
      font-style: normal

.map
  border-radius: 25px
  width: 569px
  height: 385px
  overflow: hidden
  @media (max-width: 549.5px)
    display: flex
    justify-content: center
    width: 100%

