:root {
  --title-font: "saintghetto", sans-serif;
  --text-font: "Montserrat", sans-serif;
}

::selection {
  color: #fff;
  background: #ff5400;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--text-font);
}

a {
  color: #fff;
  text-decoration-line: none;
  text-decoration-color: #fff;
}

.pb30 {
  padding-bottom: 30px;
}

.p60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.height90 {
  min-height: 90vh;
}

.header {
  background-color: #000;
  padding-top: 15px;
}

@media (max-width: 991.5px) {
  .header {
    width: 100%;
    z-index: 2;
    background-color: #0000;
    position: absolute;
    top: 0;
  }
}

@media (max-width: 991.5px) {
  .header-secondary {
    display: none;
  }
}

@media (max-width: 559.5px) {
  .header-phone {
    display: none;
  }
}

.header-wr {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 400.5px) {
  .header-wr {
    justify-content: center;
  }
}

.header-left, .header-right {
  grid-gap: 30px;
  grid-auto-flow: column;
  align-items: center;
  display: grid;
}

@media (max-width: 400.5px) {
  .header-left {
    display: none;
  }
}

.logo {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 369.5px) {
  .sign-up {
    font-size: 16px;
  }
}

.sign-up__full {
  display: none;
}

@media (max-width: 369.5px) {
  .sign-up__full {
    display: inline;
  }
}

.mobile-header {
  display: none;
}

@media (max-width: 991.5px) {
  .mobile-header {
    width: 362px;
    height: 82px;
    z-index: 10;
    background-color: #000;
    border: 6px solid #fff;
    border-radius: 35px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    bottom: 29px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mobile-nav {
  display: none;
}

@media (max-width: 991.5px) {
  .mobile-nav {
    width: 100%;
    height: 70px;
    z-index: 10;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

.mobile-menu {
  width: 350px;
  display: flex;
}

.mobile-menu__list {
  width: 70px;
  height: 70px;
  z-index: 10;
  list-style: none;
  position: relative;
}

.mobile-menu__link {
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.mobile-menu__icon {
  text-align: center;
  font-size: 1.5rem;
  line-height: 75px;
  transition: all .5s ease-out;
  display: block;
  position: relative;
}

.mobile-menu__icon svg {
  height: 25px;
}

.mobile-menu__icon svg path {
  fill: #000;
  transition: fill .4s;
}

.mobile-menu__list.active svg path {
  fill: #fff;
}

.mobile-menu__list.active .mobile-menu__icon {
  transform: translateY(-35px);
}

.mobile-menu__text {
  color: #000;
  letter-spacing: .05em;
  opacity: 0;
  font-size: .75em;
  font-weight: 400;
  transition: all .5s;
  position: absolute;
  transform: translateY(20px);
}

.mobile-menu__list.active .mobile-menu__text {
  opacity: 1;
  transform: translateY(10px);
}

.mobile-menu__indicator {
  width: 70px;
  height: 70px;
  background-color: #ff5400;
  border: 6px solid #000;
  border-radius: 50%;
  list-style: none;
  transition: all .5s ease-out;
  position: absolute;
  top: -50%;
}

.mobile-menu__list:nth-child(1).active ~ .mobile-menu__indicator {
  transform: translateX(0);
}

.mobile-menu__list:nth-child(2).active ~ .mobile-menu__indicator {
  transform: translateX(70px);
}

.mobile-menu__list:nth-child(3).active ~ .mobile-menu__indicator {
  transform: translateX(140px);
}

.mobile-menu__list:nth-child(4).active ~ .mobile-menu__indicator {
  transform: translateX(210px);
}

.mobile-menu__list:nth-child(5).active ~ .mobile-menu__indicator {
  transform: translateX(280px);
}

.button {
  color: #fff;
  align-items: center;
  transition: color .4s;
  display: flex;
}

.button:hover {
  color: #bbb;
}

.button__pre {
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.button__pre img, .button__pre svg {
  width: 100%;
}

.button__body {
  justify-content: center;
  align-items: center;
  font-weight: 800;
  display: flex;
}

.button__post {
  margin-left: 10px;
}

.button__post-sale {
  color: #fff;
  background-color: #000;
  border-radius: 25px;
  padding: 3px 10px;
}

.button-orange {
  color: #000;
  background-color: #ff5400;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 800;
  transition: background-color .4s;
}

.button-orange:hover {
  color: #000;
  background-color: #ff5400be;
}

.button-main {
  background-color: #ff5400;
  border-radius: 25px;
  justify-self: baseline;
  padding: 10px 15px;
  font-weight: 800;
  transition: background-color .4s;
}

.button-main:hover {
  color: #fff;
  background-color: #ff5400be;
}

.button-underline:hover {
  text-decoration-line: underline;
}

.main {
  background-color: #000;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (max-width: 991.5px) {
  .main {
    padding-top: 0;
    padding-bottom: 30px;
  }
}

.main-wr {
  height: 540px;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 991.5px) {
  .main-wr {
    flex-flow: row-reverse;
    position: relative;
  }
}

@media (max-width: 499.5px) {
  .main-wr {
    height: 85vh;
  }
}

.main-left {
  grid-gap: 40px;
  align-content: center;
  display: grid;
}

@media (max-width: 991.5px) {
  .main-left {
    z-index: 2;
    position: absolute;
    bottom: 100px;
    left: 0;
  }
}

@media (max-width: 499.5px) {
  .main-left {
    width: 100%;
    background-color: #000;
    bottom: 20px;
    box-shadow: 0 0 20px 50px #000;
  }
}

.main-title {
  font-family: var(--title-font);
  letter-spacing: 5px;
  word-spacing: 0;
  color: #fff;
  cursor: default;
  font-size: 40px;
  font-weight: 800;
}

@media (max-width: 450.5px) {
  .main-title {
    font-size: 34px;
  }
}

@media (max-width: 450.5px) {
  .main-title {
    font-size: 28px;
  }
}

@media (max-width: 345.5px) {
  .main-title {
    font-size: 24px;
  }
}

.main-subtitle {
  color: #ddd;
  cursor: default;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 450.5px) {
  .main-subtitle {
    font-size: 18px;
  }
}

@media (max-width: 991.5px) {
  .main-right {
    position: relative;
    right: -15px;
  }
}

.main-right__img {
  user-select: none;
  border-radius: 0 30px 30px 0;
  position: relative;
  overflow: hidden;
}

@media (max-width: 991.5px) {
  .main-right__img {
    border-radius: 0;
  }
}

.main-right__img:before {
  content: "";
  width: 100%;
  z-index: 1;
  background: linear-gradient(90deg, #000 0%, #fff0 20%);
  position: absolute;
  top: 0;
  bottom: 0;
}

.main-right__img img {
  transform: translateX(2px);
}

.main-selection {
  background-color: #ff5400;
  border-radius: 20px;
  padding: 0 10px;
}

.video {
  justify-content: center;
  align-items: center;
  display: flex;
}

.video iframe {
  width: 100%;
}

@media (max-width: 450.5px) {
  .video iframe {
    height: 400px;
    border-radius: 0;
  }
}

.features {
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.feature {
  width: 320px;
  grid-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  grid-template-rows: 86px 57px 40px;
  align-items: center;
  padding: 20px;
  display: grid;
}

.feature__img {
  justify-content: center;
  align-items: center;
  display: flex;
}

.feature__img svg {
  height: 100%;
}

.feature__title {
  text-align: center;
  color: #1e1e1e;
  cursor: default;
  user-select: none;
  font-size: 24px;
  font-weight: 800;
}

.feature__subtitle {
  color: #8a8a8a;
  text-align: center;
  cursor: default;
  user-select: none;
  font-size: 14px;
}

.icon-features {
  height: 80px;
}

.tg-bot-wr {
  justify-content: space-between;
  gap: 100px;
  display: flex;
}

@media (max-width: 991.5px) {
  .tg-bot-wr {
    flex-direction: column;
    gap: 40px;
  }
}

.tg-bot {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.tg-bot-text {
  font-size: 22px;
}

@media (max-width: 991.5px) {
  .tg-bot-text {
    font-size: 15px;
  }
}

.price-toggle {
  min-width: 100px;
  color: #fff;
  border-radius: 10px;
  justify-content: center;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
}

@media (max-width: 600px) {
  .price-toggle {
    flex-wrap: wrap;
    gap: 3px;
  }
}

.price-toggle__button {
  cursor: pointer;
  background-color: #2b2831;
  border-right: 1px solid #000;
  padding: 10px 20px;
  transition: all .3s;
}

.price-toggle__button:first-child {
  border-radius: 15px 0 0 15px;
}

.price-toggle__button:last-child {
  border-right: none;
  border-radius: 0 15px 15px 0;
}

.price-toggle__button.active {
  background-color: #ff5500e0;
}

.price-toggle__button:hover {
  background-color: #ff5400;
}

@media (max-width: 600px) {
  .price-toggle__button {
    text-align: center;
    width: calc(50% - 3px);
  }

  .price-toggle__button:nth-child(1) {
    border-radius: 15px 0 0;
  }

  .price-toggle__button:nth-child(2) {
    border-radius: 0 15px 0 0;
  }

  .price-toggle__button:nth-child(3) {
    border-radius: 0 0 0 15px;
  }

  .price-toggle__button:nth-child(4) {
    border-radius: 0 0 15px;
  }
}

@media (max-width: 400px) {
  .price-toggle__button {
    font-size: 12px;
  }
}

.rent-wr {
  background-color: #3f3b46;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 10px;
}

.rent-toggle {
  border-radius: 15px;
  margin-bottom: 10px;
  display: inline-flex;
  overflow: hidden;
}

.rent-toggle__button {
  color: #fff;
  cursor: pointer;
  background-color: #2b2831;
  border-right: 1px solid #000;
  padding: 5px 20px;
  transition: all .3s;
}

.rent-toggle__button:last-child {
  border-right: none;
}

.rent-toggle__button.active {
  background-color: #ff5500e0;
}

.rent-toggle__button:hover {
  background-color: #ff5400;
}

@media (max-width: 549.5px) {
  .rent-toggle__button {
    padding: 5px 10px;
    font-size: 12px;
  }
}

.rent-text {
  color: #ccc;
  max-width: 700px;
  font-size: 14px;
}

@media (max-width: 991.5px) {
  .rent-text {
    max-width: 460px;
  }
}

@media (max-width: 549.5px) {
  .rent-text {
    max-width: 210px;
  }
}

.prices-wr {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 991.5px) {
  .prices-wr {
    overflow-x: hidden;
  }
}

.prices {
  width: 1000px;
  background-color: #2b2831;
  border-radius: 20px;
  padding: 15px;
  display: none;
  overflow-x: hidden;
}

.n-prices {
  width: auto;
  flex-direction: column;
}

.n-abon-wr {
  grid-auto-flow: column;
  gap: 15px;
  display: grid;
}

@media (max-width: 991.5px) {
  .n-abon-wr {
    flex-direction: column-reverse;
    display: flex;
  }
}

.n-abon-delimiter {
  width: 1px;
  background-color: #3f3b46;
}

@media (max-width: 991.5px) {
  .n-abon-delimiter {
    width: 100%;
    height: 1px;
  }
}

.n-abon-block {
  color: #fff;
  gap: 15px;
  display: grid;
}

.n-abon-block__title {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

@media (max-width: 991.5px) {
  .n-abon-block {
    grid-template: "a a" min-content
                   "b c" min-content
                   / 1fr 1fr;
  }

  .n-abon-block .n-abon-block__title {
    grid-area: a;
  }

  .n-abon-block .n-card:nth-child(1) {
    grid-area: b;
  }

  .n-abon-block .n-card:nth-child(2) {
    grid-area: c;
  }
}

@media (max-width: 549.5px) {
  .n-abon-block {
    grid-template: "a" min-content
                   "b" min-content
                   "c" min-content
                   / 230px;
  }
}

.n-card {
  width: 230px;
  background-color: #3f3b46;
  border-radius: 15px;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  display: flex;
}

.n-card-price-info {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.n-card-cashback {
  height: 18px;
  width: 100%;
  align-items: center;
  display: flex;
}

.n-card-cashback__cashback {
  flex-grow: 1;
  font-size: 12px;
  font-weight: 700;
}

.n-card-cashback__oldprice {
  font-size: 14px;
  font-weight: 700;
  text-decoration-line: line-through;
}

.n-card-price {
  width: 100%;
  align-items: center;
  display: flex;
}

.n-card-price__price {
  flex-grow: 1;
  font-size: 28px;
  font-weight: 700;
}

.n-card-price__min {
  color: #ccc;
  font-size: 16px;
}

.n-card-total {
  width: 100%;
  color: #ccc;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.n-card-total__total {
  flex-grow: 1;
}

.n-card-total__old {
  text-decoration-line: line-through;
}

.n-card-amount {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
}

a.n-card-button {
  height: 40px;
  width: 200px;
  cursor: pointer;
  color: #2b2831;
  background-color: #fff;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  transition: background-color .3s, color .3s;
  display: flex;
}

a.n-card-button:hover {
  color: #fff;
  background-color: #ff5400;
}

.n-register {
  color: #fff;
  margin-top: 15px;
}

@media (max-width: 991.5px) {
  .n-register {
    margin-top: 15px;
  }
}

.n-card-gorizontal {
  width: 100%;
  grid-template-columns: 200px min-content min-content;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

.n-card-gorizontal .n-card-total {
  display: none;
}

.n-card-gorizontal .n-card-amount {
  white-space: nowrap;
  width: auto;
}

@media (max-width: 991.5px) {
  .n-card-gorizontal {
    grid-template: "a b" min-content
                   "a c" min-content
                   / 200px 200px;
    gap: 8px;
  }
}

@media (max-width: 991.5px) {
  .n-card-gorizontal .n-card-price-info {
    grid-area: a;
  }
}

@media (max-width: 991.5px) {
  .n-card-gorizontal .n-card-amount {
    text-align: center;
    grid-area: b;
  }
}

@media (max-width: 991.5px) {
  .n-card-gorizontal .n-card-button {
    grid-area: c;
  }
}

@media (max-width: 549.5px) {
  .n-card-gorizontal {
    grid-template: "a" min-content
                   "b" min-content
                   "c" min-content
                   / 200px;
  }
}

.prices.active {
  display: flex;
}

[data="1"].prices, [data="2"].prices, [data="3"].prices {
  width: auto;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
}

.price {
  color: #fff;
  border-radius: 25px;
  padding: 40px 20px;
  transition: all .3s ease-out;
  position: relative;
}

@media (max-width: 991.5px) {
  .price {
    background-color: #2b2831;
    box-shadow: -1rem 0 3rem #000;
  }

  .price:not(:first-child) {
    margin-left: -150px;
  }

  .price.price-hover {
    transform: translate(0, -15px);
  }

  .price.price-hover ~ .price {
    transform: translateX(150px);
  }
}

@media (min-width: 991.5px) {
  .price:hover, .price.active {
    background-color: #ff5400;
    position: relative;
    transform: translateY(-10px);
  }

  .price:hover .price__btn, .price.active .price__btn {
    color: #ff5400;
    background-color: #fff;
  }

  .price:hover .price-includes__check-v, .price.active .price-includes__check-v {
    stroke: #fff;
  }

  .price:hover .price__value-normal-a, .price.active .price__value-normal-a, .price:hover .price__value-small, .price.active .price__value-small, .price:hover .price__text, .price.active .price__text, .price:hover .price-includes > .price-includes__text, .price.active .price-includes > .price-includes__text {
    color: #fff;
  }
}

.cashback {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 20px;
  left: 20px;
}

.price__value {
  height: 74px;
  grid-template: "price-normal price-small" min-content
                 "price-big price-big" min-content
                 / 1fr 1fr;
  align-items: center;
  display: grid;
}

.price__value-normal {
  grid-area: price-normal;
  font-size: 28px;
  font-weight: 800;
  transition: all .3s;
  position: relative;
}

@media (max-width: 503.5px) {
  .price__value-normal {
    font-size: 24px;
  }
}

@media (max-width: 490.5px) {
  .price__value-normal {
    font-size: 22px;
  }
}

@media (max-width: 335.5px) {
  .price__value-normal {
    font-size: 20px;
  }
}

.price__value-normal-a {
  color: #ff5400;
}

.price__value-normal-promo {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  position: absolute;
  bottom: -8px;
  left: -10px;
}

.price__value-small {
  color: #ccc;
  grid-area: price-small;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}

.price__value-big {
  color: #ccc;
  grid-area: price-big;
  padding-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.price__quantity {
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 800;
}

@media (max-width: 465.5px) {
  .price__quantity {
    font-size: 18px;
  }
}

@media (max-width: 390.5px) {
  .price__quantity {
    font-size: 16px;
  }
}

.price__text {
  color: #ccc;
  width: 210px;
  height: 65px;
  padding-bottom: 20px;
  font-size: 12px;
}

@media (max-width: 991.5px) {
  .price__text {
    width: auto;
  }
}

@media (max-width: 499.5px) {
  .price__text {
    height: 75px;
  }
}

.price__btn {
  width: 100%;
  background-color: #3f3b46;
  border-radius: 25px;
  justify-content: center;
  padding: 10px 0;
  display: flex;
}

.price-includes-wr {
  flex-direction: column;
  padding-bottom: 20px;
  display: flex;
}

.price-includes {
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.price-includes__check {
  height: 18px;
  margin-right: 15px;
}

.price-includes__check-v {
  stroke: #ff5400;
}

.price-includes__check svg {
  height: 100%;
}

.price-includes__text {
  color: #ccc;
  font-size: 12px;
}

.prices-info {
  padding: 30px 30px 0;
  display: inline-flex;
}

.prices-info__text {
  color: #eee;
  font-size: 13px;
}

[data-card][data-discount="false"] .n-card-cashback__oldprice, [data-card][data-discount="false"] .n-card-total__old {
  display: none;
}

[data-loaded="false"] .n-card * {
  color: #504d55;
  background-color: #504d55;
}

[data-loaded="false"] {
  pointer-events: none;
  user-select: none;
}

.gym-wr {
  width: 100%;
  min-height: 200px;
  grid-gap: 10px;
  user-select: none;
  grid-template-rows: repeat(3, 200px);
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "gym-1 gym-2 gym-3 gym-3"
                       "gym-1 gym-5 gym-5 gym-6"
                       "gym-7 gym-8 gym-9 gym-9";
  display: grid;
}

@media (max-width: 991.5px) {
  .gym-wr {
    grid-template-rows: repeat(4, 200px);
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "gym-1 gym-3 gym-3"
                         "gym-1 gym-5 gym-5"
                         "gym-2 gym-6 gym-7"
                         "gym-8 gym-9 gym-9";
  }
}

@media (max-width: 677.5px) {
  .gym-wr {
    grid-template-rows: repeat(5, 200px);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "gym-1 gym-2"
                         "gym-1 gym-6"
                         "gym-5 gym-5"
                         "gym-9 gym-9"
                         "gym-3 gym-3"
                         "gym-7 gym-8";
  }
}

.gym {
  color: #1e1e1e;
  background-color: #f1f6f9;
  border-radius: 20px;
  padding: 10px 20px;
  overflow: hidden;
}

.gym-black {
  color: #fff;
  background-color: #2b2831;
}

.gym__title {
  cursor: default;
  user-select: none;
  text-align: center;
  width: 0;
  padding-bottom: 10px;
  font-size: 22px;
  font-weight: 800;
  transition: all .5s;
}

@media (max-width: 449.5px) {
  .gym__title {
    font-size: 17px;
  }
}

.gym__title-soon {
  position: relative;
}

.gym__title-soon:after {
  content: "Скоро";
  color: #fff;
  background-color: #ff5400;
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
}

.gym__title-mb-20 {
  margin-bottom: -20px;
}

@media (max-width: 374.5px) {
  .gym__title-mb-20 {
    margin-bottom: -10px;
  }
}

@media (max-width: 374.5px) {
  .gym__title-small {
    font-size: 14px;
  }
}

.gym__img {
  width: 100%;
  height: calc(100% - 30px);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.gym__img img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  transition: all .5s;
}

.gym__img-text {
  cursor: default;
  transition: all .5s;
  position: absolute;
  transform: translateY(260px);
}

@media (max-width: 499.5px) {
  .gym__img-text {
    font-size: 14px;
  }
}

@media (max-width: 499.5px) {
  .gym__img-text-small {
    font-size: 12px;
  }
}

@media (max-width: 374.5px) {
  .gym__img-text-small {
    font-size: 10px;
  }
}

.gym:hover .gym__title {
  width: 100%;
}

.gym:hover .gym__title-soon:after {
  margin-left: 10px;
}

.gym:hover .gym__img .gym__img-text {
  transform: translateY(0%);
}

.gym:hover .gym__img img {
  opacity: 0;
}

.partners-wr {
  grid-gap: 60px;
  width: 100%;
  user-select: none;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  display: grid;
}

@media (max-width: 711px) {
  .partners-wr {
    grid-auto-flow: row;
  }
}

@media (max-width: 415px) {
  .partners-wr {
    grid-gap: 20px;
  }
}

.partner {
  position: relative;
  overflow: hidden;
}

.partner__desc {
  text-align: center;
  color: #222;
  background-color: #fffffffa;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 800;
  transition: all .3s;
  display: flex;
  position: absolute;
  inset: 0;
  transform: translateY(100%);
}

.partner:hover .partner__desc {
  transform: translateY(0);
}

.partner__img {
  height: 80px;
}

.partner__img img {
  height: 100%;
}

@media (max-width: 415px) {
  .partner__img {
    height: 50px;
  }
}

.coaches {
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
  display: flex;
}

.coach {
  width: 300px;
  height: 450px;
  background-color: #fff;
  border-radius: 20px;
  margin: 0 20px 20px;
  padding-bottom: 50px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}

.coach.active {
  height: 950px;
}

.coach-6.active {
  height: 960px;
}

.coach-2.active {
  height: 880px;
}

.coach-7.active {
  height: 1060px;
}

.coach-8.active {
  height: 1020px;
}

.coach-12.active {
  height: 920px;
}

.coach-13.active {
  height: 980px;
}

.coach-14.active {
  height: 910px;
}

.coach-15.active {
  height: 990px;
}

.coach-16.active {
  height: 880px;
}

.coach-arrow {
  width: 300px;
  height: 30px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 3px;
}

.coach-arrow-1 {
  width: 30px;
  height: 3px;
  transform-origin: center;
  background-color: #ff5400;
  border-radius: 10px;
  transition: all .8s;
  transform: rotate(30deg)translateX(3px);
}

.coach-arrow-2 {
  width: 30px;
  height: 3px;
  transform-origin: center;
  background-color: #ff5400;
  border-radius: 10px;
  transition: all .8s;
  transform: rotate(-30deg)translateX(-3px);
}

.coach.active .coach-arrow-1 {
  transform: rotate(-30deg)translateX(3px);
}

.coach.active .coach-arrow-2 {
  transform: rotate(30deg)translateX(-3px);
}

.coach__img {
  width: 300px;
  height: 350px;
  border-radius: 20px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 5px #0003;
}

.coach__img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.coach__social {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.coach.active .coach__img {
  box-shadow: 0 3px 3px #0006;
}

.coach__name {
  color: #222;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 26px;
  font-weight: 700;
  line-height: 28px;
  transition: all .5s;
}

.coach.active .coach__name {
  font-size: 24px;
}

.coach__prof {
  color: #222;
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  transition: all .5s;
}

.coach__prof-small {
  font-size: 14px;
}

.coach.active .coach__prof {
  margin-bottom: 10px;
}

.coash__info {
  padding-left: 20px;
  padding-right: 20px;
}

.coash__tel {
  color: #333;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  display: block;
}

.directions {
  margin-bottom: 10px;
}

.directions__title {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
}

.directions__list {
  list-style: none;
}

.directions__item {
  margin-bottom: 8px;
  padding-left: 24px;
  font-size: 12px;
  font-weight: 400;
  position: relative;
}

.direction__check {
  position: absolute;
  top: 0;
  left: 0;
}

.direction__check-o {
  fill: #ff5400;
}

.direction__check-v {
  stroke: #fff;
}

.education {
  margin-bottom: 10px;
}

.education__title {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
}

.education__text {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
}

.coach-prices {
  margin-bottom: 10px;
}

.coach-prices__title {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
}

.coach-prices__text {
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 400;
}

.training {
  width: 100%;
  grid-gap: 20px;
  user-select: none;
  grid-template-columns: 600px 1fr;
  align-items: center;
  display: grid;
}

.training-img {
  height: 400px;
  width: 100%;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.training-img__item {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.training-img__text {
  text-align: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: default;
  user-select: none;
  background-color: #ffffffe6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  transition: all .4s;
  display: flex;
  position: absolute;
}

.training-img__text:hover {
  opacity: 1;
}

.training-img__link {
  color: #ff5400;
  margin-top: 15px;
}

.training-img__link:hover {
  text-decoration: underline;
}

.training-list {
  list-style: none;
}

.training-list__item {
  cursor: pointer;
  user-select: none;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 800;
  transition: all .3s;
}

.training-list__item.active {
  color: #ff5400;
}

.training-list__item:hover {
  color: #ff5400;
  padding-left: 5px;
}

.training-img__hover-dot {
  position: absolute;
  top: 10px;
  left: 10px;
}

.training-img__hover-dot-1 {
  width: 16px;
  height: 16px;
  background-color: #ff540080;
  border-radius: 50%;
  position: absolute;
}

.training-img__hover-dot-2 {
  width: 10px;
  height: 10px;
  background-color: #ff5400cc;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
}

.address {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 839.5px) {
  .address {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

.contacts {
  height: 400px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media (max-width: 839.5px) {
  .contacts {
    height: auto;
    padding: 30px 0;
  }
}

.contacts__item {
  height: 30px;
  margin-bottom: 10px;
}

.contacts__item .button__body {
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
}

.map {
  width: 569px;
  height: 385px;
  border-radius: 25px;
  overflow: hidden;
}

@media (max-width: 549.5px) {
  .map {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}

.footer {
  color: #fff;
  background-color: #000;
}

@media (max-width: 991.5px) {
  .footer {
    padding-bottom: 120px;
  }
}

.footer__content {
  justify-content: space-between;
  display: flex;
}

.company-info__ip, .company-info__address {
  margin-bottom: 2px;
  font-size: 11px;
  display: block;
}

.company-info__offer {
  margin-bottom: 2px;
  font-size: 11px;
  text-decoration: underline;
  display: block;
}

.company-info__tel {
  text-align: right;
  margin-bottom: 2px;
  font-size: 13px;
  display: block;
}

.company-info__mail {
  margin-bottom: 2px;
  font-size: 13px;
  display: block;
}

@media (max-width: 991.5px) {
  .company-info_right {
    display: none;
  }
}

.container {
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 15px;
}

.section {
  width: 100%;
  background-color: #000;
}

.section-white {
  width: 100%;
  background-color: #fff;
}

.title {
  font-family: var(--title-font);
  letter-spacing: 5px;
  word-spacing: 0;
  color: #fff;
  cursor: default;
  font-size: 40px;
  font-weight: 800;
}

@media (max-width: 991.5px) {
  .title {
    text-align: center;
  }
}

@media (max-width: 659.5px) {
  .title {
    font-size: 36px;
  }
}

@media (max-width: 400.5px) {
  .title {
    font-size: 28px;
  }
}

.title-white {
  color: #222;
}

.subtitle {
  color: #bbb;
  font-size: 16px;
  font-weight: normal;
}

@media (max-width: 991.5px) {
  .subtitle {
    text-align: center;
  }
}

@media (max-width: 400.5px) {
  .subtitle {
    font-size: 14px;
  }
}

.subtitle-white {
  color: #222;
}

.none {
  display: none;
}

.social-1m, .social-2m, .social-3m {
  opacity: 0;
}

@media (max-width: 991.5px) {
  .temp-none {
    display: none;
  }
}

@media (max-width: 991.5px) {
  .price-p0 {
    padding: 0;
  }
}

.alert {
  width: 270px;
  z-index: 90;
  background-color: #2b2831;
  border-radius: 10px;
  padding: 15px;
  transition: all .3s;
  position: fixed;
  bottom: 120px;
  left: 15px;
}

.alert__title {
  color: #fff;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 800;
}

.alert__text {
  color: #fff;
  font-size: 14px;
}

.alert-button {
  text-align: center;
  width: 100%;
  color: #fff;
  cursor: pointer;
  background-color: #ff5400;
  border: none;
  border-radius: 20px;
  justify-self: baseline;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 800;
  transition: background-color .4s;
  display: inline-block;
}

.alert-button:hover {
  color: #fff;
  background-color: #ff5400be;
}

.alert-close {
  width: 30px;
  height: 30px;
  color: #fff;
  cursor: pointer;
  background-color: #39373f;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.alert-close:hover {
  background-color: #2b2831;
}

.alert-hide {
  left: -280px;
}

/*# sourceMappingURL=index.6719e8b7.css.map */
