.button
  display: flex
  align-items: center
  color: #fff
  transition: color .4s

.button:hover
  color: #bbb

.button__pre
  display: flex
  justify-content: center
  align-items: center
  margin-right: 10px
  width: 20px
  & img
    width: 100%
  & svg
    width: 100%

.button__body
  display: flex
  justify-content: center
  align-items: center
  font-weight: 800

.button__post
  margin-left: 10px

.button__post-sale
  color: #fff
  background-color: #000
  border-radius: 25px
  padding: 3px 10px

.button-orange
  background-color: #FF5400
  color: #000
  font-size: 14px
  font-weight: 800
  padding: 5px 10px
  border-radius: 5px
  transition: background-color .4s

.button-orange:hover
  background-color: #FF5400BE
  color: #000

.button-main
  justify-self: baseline
  background-color: #FF5400
  padding: 10px 15px
  border-radius: 25px
  font-weight: 800
  transition: background-color .4s

.button-main:hover
  background-color: #FF5400BE
  color: #fff

.button-underline:hover
  text-decoration-line: underline
