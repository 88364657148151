.footer
  color: #fff
  background-color: #000
  @media (max-width: 991.5px)
    padding-bottom: 120px

.footer__content
  display: flex
  justify-content: space-between

.company-info__ip
  display: block
  margin-bottom: 2px
  font-size: 11px

.company-info__address
  display: block
  margin-bottom: 2px
  font-size: 11px

.company-info__offer
  display: block
  margin-bottom: 2px
  font-size: 11px
  text-decoration: underline

.company-info__tel
  display: block
  text-align: right
  margin-bottom: 2px
  font-size: 13px

.company-info__mail
  display: block
  margin-bottom: 2px
  font-size: 13px

.company-info_right
  @media (max-width: 991.5px)
    display: none
    


