\:root
  --title-font: 'saintghetto', sans-serif
  --text-font: 'Montserrat', sans-serif

\::selection
  background: #ff5400
  color: #fff
*
  margin: 0
  padding: 0
  box-sizing: border-box

html
  scroll-behavior: smooth

body
  font-family: var(--text-font)

a
  text-decoration-line: none
  text-decoration-color: #fff
  color: #fff

.pb30
  padding-bottom: 30px

.p60
  padding-bottom: 60px
  padding-top: 60px

.height90
  min-height: 90vh


