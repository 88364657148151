.main
  background-color: #000
  padding-bottom: 60px
  padding-top: 60px
  @media (max-width: 991.5px)
    padding-top: 0
    padding-bottom: 30px

.main-wr
  height: 540px
  display: flex
  justify-content: space-between
  @media (max-width: 991.5px)
    position: relative
    flex-flow: row-reverse
  @media (max-width: 499.5px)
    height: 85vh

.main-left
  display: grid
  align-content: center
  grid-gap: 40px
  @media (max-width: 991.5px)
    position: absolute
    left: 0
    bottom: 100px
    z-index: 2
  @media (max-width: 499.5px)
    width: 100%
    bottom: 20px
    background-color: black
    box-shadow: 0 0 20px 50px black

.main-title
  font-family: var(--title-font)
  letter-spacing: 5px
  word-spacing: 0
  color: #fff
  font-size: 40px
  font-weight: 800
  cursor: default
  @media (max-width: 450.5px)
    font-size: 34px
  @media (max-width: 450.5px)
    font-size: 28px
  @media (max-width: 345.5px)
    font-size: 24px

.main-subtitle
  color: #ddd
  font-size: 18px
  font-weight: 400
  cursor: default
  @media (max-width: 450.5px)
    font-size: 18px

.main-right
  @media (max-width: 991.5px)
    position: relative
    right: -15px

.main-right__img
  position: relative
  border-radius: 0 30px 30px 0
  overflow: hidden
  user-select: none
  @media (max-width: 991.5px)
    border-radius: 0

.main-right__img:before
  content: ''
  position: absolute
  top: 0
  bottom: 0
  width: 100%
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 20%)
  z-index: 1

.main-right__img img
  transform: translateX(2px)

.main-selection
  background-color: #FF5400
  border-radius: 20px
  padding: 0 10px

.video
  display: flex
  justify-content: center
  align-items: center
  & iframe
    width: 100%
    @media (max-width: 450.5px)
      border-radius: 0
      height: 400px



