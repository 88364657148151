.container
  max-width: 1050px
  padding: 0 15px
  margin: 0 auto

.section
  width: 100%
  background-color: #000

.section-white
  width: 100%
  background-color: #fff

.title
  font-family: var(--title-font)
  letter-spacing: 5px
  word-spacing: 0
  font-size: 40px
  font-weight: 800
  color: #fff
  cursor: default
  @media (max-width: 991.5px)
    text-align: center
  @media (max-width: 659.5px)
    font-size: 36px
  @media (max-width: 400.5px)
    font-size: 28px

.title-white
  color: #222

.subtitle
  font-size: 16px
  font-weight: normal
  color: #bbb
  @media (max-width: 991.5px)
    text-align: center
  @media (max-width: 400.5px)
    font-size: 14px

.subtitle-white
  color: #222

.none
  display: none

.social-1m, .social-2m, .social-3m
  opacity: 0

.temp-none
  @media(max-width: 991.5px)
    display: none

.price-p0
  @media (max-width: 991.5px)
    padding: 0
