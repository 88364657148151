.price-toggle
	min-width: 100px
	color: #fff
	display: flex
	justify-content: center
	border-radius: 10px
	margin-bottom: 30px
	margin-left: 10px
	margin-right: 10px
	@media (max-width: 600px)
		flex-wrap: wrap
		gap: 3px

.price-toggle__button
	background-color: #2B2831
	border-right: 1px solid #000
	padding: 10px 20px
	cursor: pointer
	transition: .3s
	&:first-child
		border-radius: 15px 0 0 15px
	&:last-child
		border-radius: 0 15px 15px 0
		border-right: none
	&.active
		background-color: #ff5500e0
	&:hover
		background-color: #ff5400
	@media (max-width: 600px)
		text-align: center
		width: calc(50% - 3px)
		&:nth-child(1)
			border-radius: 15px 0 0 0
		&:nth-child(2)
			border-radius: 0 15px 0 0
		&:nth-child(3)
			border-radius: 0 0 0 15px
		&:nth-child(4)
			border-radius: 0 0 15px 0
	@media (max-width: 400px)
		font-size: 12px

.rent-wr
	padding: 10px
	border-radius: 15px
	background-color: #3F3B46
	margin-bottom: 10px

.rent-toggle
	display: inline-flex
	border-radius: 15px
	overflow: hidden
	margin-bottom: 10px

.rent-toggle__button
	color: #fff
	background-color: #2B2831
	border-right: 1px solid #000
	padding: 5px 20px
	cursor: pointer
	transition: .3s
	&:last-child
		border-right: none
	&.active
		background-color: #ff5500e0
	&:hover
		background-color: #ff5400
	@media(max-width: 549.5px)
		padding: 5px 10px
		font-size: 12px

.rent-text
	font-size: 14px
	color: #CCCCCC
	max-width: 700px
	@media (max-width: 991.5px)
		max-width: 460px
	@media(max-width: 549.5px)
		max-width: 210px


.prices-wr
	display: flex
	justify-content: center
	align-items: center
	@media (max-width: 991.5px)
		overflow-x: hidden

.prices
	display: none
	background-color: #2B2831
	border-radius: 20px
	width: 1000px
	padding: 15px
	overflow-x: hidden

// Новые карточки с ценами
.n-prices
	flex-direction: column
	width: auto
.n-abon-wr
	display: grid
	grid-auto-flow: column
	gap: 15px
	@media(max-width: 991.5px)
		display: flex
		flex-direction: column-reverse
.n-abon-delimiter
	width: 1px
	background-color: #3F3B46
	@media (max-width: 991.5px)
		width: 100%
		height: 1px
.n-abon-block
	display: grid
	gap: 15px
	color: #FFFFFF
	&__title
		text-align: center
		font-weight: 500
		font-size: 16px
		line-height: 20px

.n-abon-block
	@media(max-width: 991.5px)
		grid-template-columns: 1fr 1fr
		grid-template-rows: min-content min-content
		grid-template-areas: 'a a' 'b c'
		& .n-abon-block__title
			grid-area: a
		& .n-card:nth-child(1)
			grid-area: b
		& .n-card:nth-child(2)
			grid-area: c
.n-abon-block
	@media(max-width: 549.5px)
		grid-template-columns: 230px
		grid-template-rows: min-content min-content min-content
		grid-template-areas: 'a' 'b' 'c'

.n-card
	display: flex
	flex-direction: column
	gap: 15px
	background-color: #3F3B46
	border-radius: 15px
	padding: 15px
	width: 230px
.n-card-price-info
	display: flex
	flex-direction: column
	gap: 4px
.n-card-cashback
	display: flex
	align-items: center
	height: 18px
	width: 100%
	&__cashback
		font-weight: 700
		font-size: 12px
		flex-grow: 1
	&__oldprice
		font-weight: 700
		font-size: 14px
		text-decoration-line: line-through
.n-card-price
	display: flex
	align-items: center
	width: 100%
	&__price
		font-weight: 700
		font-size: 28px
		flex-grow: 1
	&__min
		font-size: 16px
		color: #CCCCCC
.n-card-total
	display: flex
	align-items: center
	width: 100%
	font-size: 14px
	color: #CCCCCC
	&__total
		flex-grow: 1
	&__old
		text-decoration-line: line-through
.n-card-amount
	width: 100%
	font-weight: 700
	font-size: 20px
a.n-card-button
	display: flex
	justify-content: center
	align-items: center
	height: 40px
	width: 200px
	border-radius: 25px
	cursor: pointer
	font-weight: 700
	font-size: 16px
	background-color: #FFFFFF
	color: #2B2831
	transition: background-color .3s, color .3s
	&:hover
		background-color: #FF5400
		color: #FFFFFF

.n-register
	color: #FFFFFF
	margin-top: 15px
	@media(max-width: 991.5px)
		margin-top: 15px
.n-card-gorizontal
	display: grid
	grid-template-columns: 200px min-content min-content
	justify-content: space-between
	align-items: center
	width: 100%
	& .n-card-total
		display: none
	& .n-card-amount
		white-space: nowrap
		width: auto
.n-card-gorizontal
	@media(max-width: 991.5px)
		grid-template-columns: 200px 200px
		grid-template-rows: min-content min-content
		grid-template-areas: 'a b' 'a c'
		gap: 8px
.n-card-gorizontal .n-card-price-info
	@media(max-width: 991.5px)
		grid-area: a
.n-card-gorizontal .n-card-amount
	@media(max-width: 991.5px)
		text-align: center
		grid-area: b
.n-card-gorizontal .n-card-button
	@media(max-width: 991.5px)
		grid-area: c
.n-card-gorizontal
	@media(max-width: 549.5px)
		grid-template-columns: 200px
		grid-template-rows: min-content min-content min-content
		grid-template-areas: 'a' 'b' 'c'

///////////////
.prices.active
	display: flex

[data="1"].prices,
[data="2"].prices,
[data="3"].prices
	justify-content: center
	width: auto
	margin-left: 15px
	margin-right: 15px

.price
	position: relative
	border-radius: 25px
	padding: 40px 20px
	color: #fff
	transition: .3s ease-out
	@media (max-width: 991.5px)
		background-color: #2B2831
		box-shadow: -1rem 0 3rem #000
		&:not(:first-child)
			margin-left: -150px
		&.price-hover
			transform: translate(0, -15px)
		&.price-hover ~ .price
			transform: translateX(150px)


@media (min-width: 991.5px)
	.price:hover, .price.active
		position: relative
		transform: translateY(-10px)
		background-color: #ff5400
		& .price__btn
			background-color: #fff
			color: #ff5400
		& .price-includes__check-v
			stroke: #fff
		& .price__value-normal-a
			color: #fff
		& .price__value-small
			color: #fff
		& .price__text
			color: #fff
		& .price-includes>.price-includes__text
			color: #fff

.cashback
	position: absolute
	top: 20px
	left: 20px
	font-size: 14px
	color: #fff
	font-weight: 600

.price__value
	display: grid
	grid-template-columns: 1fr 1fr
	grid-template-rows: min-content min-content
	grid-template-areas: 'price-normal price-small' 'price-big price-big'
	align-items: center
	height: 74px
	&-normal
		position: relative
		grid-area: price-normal
		font-size: 28px
		font-weight: 800
		transition: .3s
		@media (max-width: 503.5px)
			font-size: 24px
		@media (max-width: 490.5px)
			font-size: 22px
		@media (max-width: 335.5px)
			font-size: 20px
		&-a
			color: #ff5400
		&-promo
			color: #fff
			font-size: 14px
			font-weight: 400
			text-decoration: line-through
			position: absolute
			bottom: -8px
			left: -10px
	&-small
		grid-area: price-small
		display: inline-block
		font-size: 16px
		color: #ccc
		font-weight: 400
	&-big
		grid-area: price-big
		padding-top: 10px
		font-size: 14px
		color: #ccc
		font-weight: 400

.price__quantity
	font-size: 20px
	font-weight: 800
	padding-bottom: 20px
	@media (max-width: 465.5px)
		font-size: 18px
	@media (max-width: 390.5px)
		font-size: 16px

.price__text
	font-size: 12px
	color: #ccc
	padding-bottom: 20px
	width: 210px
	height: 65px
	@media (max-width: 991.5px)
		width: auto
	@media (max-width: 499.5px)
		height: 75px

.price__btn
	display: flex
	justify-content: center
	width: 100%
	padding: 10px 0
	background-color: #3f3b46
	border-radius: 25px

.price-includes-wr
	display: flex
	flex-direction: column
	padding-bottom: 20px

.price-includes
	display: flex
	align-items: center
	margin-bottom: 5px

.price-includes__check
	margin-right: 15px
	height: 18px
	&-v
		stroke: #ff5400
	& svg
		height: 100%

.price-includes__text
	font-size: 12px
	color: #ccc

.prices-info
	display: inline-flex
	padding: 30px 30px 0

.prices-info__text
	font-size: 13px
	color: #eee

[data-card][data-discount="false"] .n-card-cashback__oldprice,
[data-card][data-discount="false"] .n-card-total__old
	display: none

[data-loaded="false"] .n-card *
	background-color: #504d55
	color: #504d55
[data-loaded="false"]
	pointer-events: none
	user-select: none

