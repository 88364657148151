.training
  width: 100%
  //border: 1px solid black
  display: grid
  grid-gap: 20px
  grid-template-columns: 600px 1fr
  align-items: center
  user-select: none

.training-img
  position: relative
  height: 400px
  width: 100%
  border-radius: 20px
  overflow: hidden
  &__item
    height: 400px
    width: 100%
    object-fit: cover
.training-img__text
  font-size: 24px
  font-weight: 400
  text-align: center
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  width: 100%
  height: 100%
  background-color: rgba(255, 255, 255, 0.9)
  opacity: 0
  transition: .4s
  cursor: default
  user-select: none
  &:hover
    opacity: 1
    
.training-img__link
  margin-top: 15px
  color: #ff5400
  &:hover
    text-decoration: underline


.training-list
  list-style: none
  &__item
    font-size: 22px
    padding-bottom: 20px
    font-weight: 800
    cursor: pointer
    transition: .3s
    user-select: none
    &.active
      color: #ff5400
    &:hover
      padding-left: 5px
      color: #ff5400

.training-img__hover-dot
  position: absolute
  top: 10px
  left: 10px
  &-1
    position: absolute
    width: 16px
    height: 16px
    border-radius: 50%
    background-color: #FF540080
  &-2
    position: absolute
    top: 3px
    left: 3px
    width: 10px
    height: 10px
    border-radius: 50%
    background-color: #FF5400CC

